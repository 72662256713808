<style scoped lang="less">
.followNav-home {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  .box {
    flex: 1;
    overflow: auto;
  }

  .home-content {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .item {
      width: calc(100% / 4 - 15px);
      height: 200px;
      margin-top: 10px;
      margin-left: 10px;
      .el-card {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      .el-card:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
      .el-icon-setting {
        font-size: 30px;
      }
      .info {
        font-size: 24px;
        text-align: center;
        img {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="followNav-home">
    <IndexHeader />
    <div class="box">
      <div class="home-content">
        <div class="item" v-for="(item, index) in list" v-bind:key="index">
          <el-card class="box-card">
            <div class="info" @click="gotoFollow(item.moduleCode)">
              <img
                v-if="item.moduleCode === 'AorticInfo'"
                src="../assets/img/followNav/AorticInfo.png"
                alt=""
              />
              <img
                v-else-if="item.moduleCode === 'Cirrhosis'"
                src="../assets/img/followNav/Cirrhosis.png"
                alt=""
              />
              <img
                v-else-if="item.moduleCode === 'PeripheralVessels'"
                src="../assets/img/followNav/PeripheralVessels.png"
                alt=""
              />
              <img
                v-else-if="item.moduleCode === 'AorticDissection'"
                src="../assets/img/followNav/AorticDissection.png"
                alt=""
              />
              <img
                v-else-if="item.moduleCode === 'NerveDavf'"
                src="../assets/img/followNav/NerveDavf.png"
                alt=""
              />
              <img
                v-else-if="item.moduleCode === 'Electrophysiology'"
                src="../assets/img/followNav/Electrophysiology.png"
                alt=""
              />
              <img
                v-else-if="item.moduleCode === 'CoronaryInfo'"
                src="../assets/img/followNav/CoronaryInfo.png"
                alt=""
              />
              <img
                v-else-if="item.moduleCode === 'Pacemaker'"
                src="../assets/img/followNav/Pacemaker.png"
                alt=""
              />
              <img
                v-else-if="item.moduleCode === 'LiverCancer'"
                src="../assets/img/followNav/LiverCancer.png"
                alt=""
              />
              <img
                v-else-if="item.moduleCode === 'AorticAneurysm'"
                src="../assets/img/followNav/AorticAneurysm.png"
                alt=""
              />
              <img
                v-else-if="item.moduleCode === 'NerveCcf'"
                src="../assets/img/followNav/NerveCcf.png"
                alt=""
              />
              <img
                v-else-if="item.moduleCode === 'NerveAneurysm'"
                src="../assets/img/followNav/AorticAneurysm.png"
                alt=""
              />
              <img
                v-else-if="item.moduleCode === 'NerveArteryStenosis'"
                src="../assets/img/followNav/AorticDissection.png"
                alt=""
              />
              <img
                v-else-if="item.moduleCode === 'NerveAvm'"
                src="../assets/img/followNav/AorticDissection.png"
                alt=""
              />
              <img
                v-else-if="item.moduleCode === 'NerveCe'"
                src="../assets/img/followNav/NerveCe.png"
                alt=""
              />
              <img
                v-else-if="item.moduleCode === 'Mitral'"
                src="../assets/img/followNav/Mitral.png"
                alt=""
              />
              <i v-else class="el-icon-setting"></i>
              <p>{{ item.moduleTitle }}</p>
              <p v-if="item.moduleEnglish">{{ item.moduleEnglish }}</p>
              <p v-else>{{ item.moduleCode }}</p>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IndexHeader from './IndexHeader.vue'
export default {
  name: 'FollowNav',
  components: { IndexHeader },
  data() {
    return {
      list: []
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    gotoFollow(val) {
      this.$router.push('/FollowHome' + '?' + `moduleCode=${val}`)
    },
    getList() {
      this.$api.get(`/v1/webconsole/study/mymodulelist?moduleType=1`).then((res) => {
        if (res.data && res.data.data.length > 0) {
          this.list = res.data.data
        }
      })
    }
  }
}
</script>
